<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <AvatarAddAvatar
                :entity="form"
                :file="form.photo"
                @mainImageUploaded="mainImageUploaded"
              />
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="type" :label="$t('type')">
                <ElSelect
                  v-model="form.type"
                  style="width: 100%"
                  :placeholder="$t('select_type')"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in agentTypesArray"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                prop="business_phone_number"
                :label="$t('phone_number')"
              >
                <ElInput
                  v-model="form.business_phone_number"
                  type="tel"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                >
                  <template #prepend>
                    <ElSelect
                      v-model="form.business_phone_number_country"
                      placeholder=""
                      filterable
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                    >
                      <template #prefix>
                        <img
                          v-if="selectedPhoneCountry"
                          :src="selectedPhoneCountry.flag"
                        />
                      </template>
                      <ElOptionGroup
                        v-for="(group, index) in countries"
                        :key="index"
                      >
                        <ElOption
                          :class="
                            form.business_phone_number_country ===
                            item.phone_country
                              ? 'selected'
                              : ''
                          "
                          v-for="item in group.options"
                          label=""
                          :value="item.phone_country"
                          :key="item.iso_code"
                        >
                          <template #default>
                            <img :src="item.flag" :alt="item.iso_code" />
                            <span
                              >{{ item.iso_code }} ({{
                                item.phone_country
                              }})</span
                            >
                          </template>
                        </ElOption>
                      </ElOptionGroup>
                    </ElSelect>
                  </template>
                </ElInput>
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { agentTypes } from '~/models/agent-types'
import CREATE_AGENT from '~/graphql/agents/mutation/createAgent.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const form = reactive({
  full_name: '',
  type: '',
  business_phone_number: '',
  business_phone_number_country: ''
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)
const countries = getModelCountries()
const { fileSizeLimit } = getFormInitialVariables()

const phoneNumberElForm = (rule, value, callback) => {
  if (!phoneNumber(value, form)) {
    return callback(t('phone_length_invalid'))
  }
  return callback()
}

const rules = {
  full_name: { required },
  business_phone_number: { phoneNumber }
}

const v$ = useVuelidate(rules, form)

const inputRules = reactive({
  full_name: [
    {
      required: true,
      message: t('full_name_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  business_phone_number: [
    {
      validator: phoneNumberElForm,
      trigger: ['blur', 'change']
    }
  ]
})

const agentTypesArray = computed(() => {
  return agentTypes.types.map((item) => {
    return {
      value: item.toLowerCase(),
      label: t('agent_types.' + item.toLowerCase())
    }
  })
})

const selectedPhoneCountry = computed(() => {
  for (const elem of countries) {
    const country = elem.options.find(
      (item) => item.phone_country === form.business_phone_number_country
    )
    if (country) {
      return country
    }
  }
  return null
})

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const variables = {
    full_name: form.full_name,
    type: form.type ? form.type.toUpperCase() : null,
    business_phone_number: form.business_phone_number,
    business_phone_number_country: form.business_phone_number_country
  }
  if (form.photo_id) {
    variables.photo_id = form.photo_id
  }
  const response = await mutation(CREATE_AGENT, variables)
  if (response.error) {
    loading.value = false
    $showError(response.error, t)
    $sentry(response.error, 'create')
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createAgent ?? null)
    loading.value = false
  }
}

const mainImageUploaded = (fileVariables) => {
  form.photo_id = fileVariables.file
}
</script>
